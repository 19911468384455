<template>
  <component
    :is="postLayout"
    :show-leaderboard-ad="loading === 0"
    :class="layoutClasses"
    :post="post"
  >
    <template>
      <client-only>
        <admin-toolbar v-if="showAdminToolbar" :post="post" :data-layer="dataLayer" />
      </client-only>
      <post-password-form
        v-if="showPasswordForm"
        :post-id="post && post.id"
        :post-password="post && post.password"
        @passwordEntered="updatePasswordEntered"
      />
      <cross-publishing-indicator
        v-if="showCrossPublishing"
        :prefixes="allSitesForPost"
        :original-site="post.originalSite"
        :post-path="post.permalink"
      />
      <hero-component
        v-if="showPost && showFeatured"
        slot="hero"
        :hero-media="featuredImage"
      />
      <loading-spinner v-if="loading !== 0" />
      <div v-else-if="showPost" class="post" data-test-id="post">
        <div
          v-if="!post.isQuizResult"
          class="post__header"
          :class="headerShouldBeHidden ? 'visually-hidden' : ''"
          data-test-id="post-header"
        >
          <h1
            class="page-heading"
            :class="`page-heading--${post.type}`"
            data-test-id="post-page-heading"
          >{{ postTitle | decodeHtml }}</h1>
          <lazy-hydrate when-visible>
            <user-byline
              v-if="!hideByline"
              :user="post.author"
              :is-cross-published="showCrossPublishing"
              :original-site="post.originalSite"
              :date="lastUpdatedOrAvailableDate"
              :display-tooltip="!isSponsoredArticle"
              :show-user-avatar="!isSponsoredArticle"
              :word-count="dataLayer.post_words"
              :post-type="post.type"
              show-date
              class="byline--inline"
            >
              <template v-slot:after>
                <text-to-speech :htmls="htmlsForSpeech" />
              </template>
            </user-byline>
          </lazy-hydrate>
          <div v-if="displayReviewedbyText" class="post__reviewedby" data-test-id="post-reviewed-by">
            <p v-html="reviewedbyText" />
          </div>
          <social-share
            v-if="!hideSocialShare"
            :post="post"
            :comment-count="totalComments"
            @goToComments="handleGoToComments"
            @openReferences="openReferences"
          />
        </div>
        <div ref="content" class="post__content" data-test-id="post-content">
          <div v-if="showExcerpt" class="post__excerpt" data-test-id="post-excerpt" v-html="post.excerpt" />
          <content-renderer :sections="contentSections" />
          <lazy-hydrate when-visible>
            <nutrition-info
              v-if="post.nutrition && post.type === 'recipe'"
              :nutrition-data="post.nutrition"
            />
          </lazy-hydrate>
          <div>
            <div
              v-if="post.showTreatmentDisclaimer && !hasAuthContent"
              class="post__treatment-disclaimer disclaimer-text"
            >
              Treatment results and side effects can vary from person to person. This treatment
              information is not meant to replace professional medical advice. Talk to your
              doctor about what to expect before starting and while taking any treatment.
            </div>
            <div
              v-if="post.showContributorDisclaimer && !hasAuthContent"
              class="post__contributor-disclaimer disclaimer-text"
              data-test-id="post-contributor-disclaimer"
            >
              This article represents the opinions, thoughts, and experiences of the author;
              none of this content has been paid for by any advertiser. The {{ $site.name }}
              team does not recommend or endorse any products or treatments discussed herein.
              Learn more about how we maintain editorial integrity
              <router-link to="/about-us">here</router-link>.
            </div>
            <div
              v-if="showDefaultSponsorDisclaimer && !hasAuthContent"
              class="post__sponsored-disclaimer disclaimer-text"
            >
              A note from {{ $site.name }}: The content of this article was provided by
              our sponsor.  {{ $site.name }} does not specifically endorse or recommend
              the program, product, medications or therapies discussed in this article.
            </div>
          </div>
          <div v-if="post.references" class="post__references-container">
            <button
              v-if="post.references.length && !hasAuthContent"
              :aria-expanded="referencesIsClosed ? 'false' : 'true'"
              aria-controls="post-references"
              :class="`
                post__references-toggle
                ${!referencesIsClosed ? 'post__references-toggle--open' : ''}
                text-button
              `"
              data-test-id="post-references-toggle"
              @click="toggleReferences()"
            >
              View references
              <icon-component
                name="caret"
                title="caret icon"
                class="post__references-icon icon--small"
              />
            </button>
            <transition name="post__references-fade">
              <div
                v-if="!referencesIsClosed"
                id="post-references"
                class="post__references"
                data-test-id="post-references"
                v-html="post.references"
              />
            </transition>
          </div>
          <reindex-this-link :post-id="post.id" />
          <template v-if="!post.originalSite || post.originalSite === $site.prefix">
            <edit-this-link :post-id="post.id" />
          </template>
          <template v-else-if="isAdmin">
            <p>
              Note to admins: This is a cross-published post
              from {{ getSiteSummary(post.originalSite) && getSiteSummary(post.originalSite).name || post.originalSite }}.
            </p>
            <edit-this-link
              v-if="getSiteSummary(post.originalSite)"
              :post-id="post.id"
              :host="getSiteSummary(post.originalSite).host"
            />
          </template>
        </div>
        <lazy-hydrate :when-visible="{ rootMargin: '150px' }">
          <reaction-grid
            v-if="!hideEngagement"
            :id="post.id"
            content-type="post"
          />
        </lazy-hydrate>
        <comment-thread
          v-if="showComments"
          id="comment-thread"
          ref="commentThread"
          :comments="post.comments"
          :total-comments="totalComments"
          :post="post"
        />
        <!-- TODO: dynamically pass in pollId based on the one the post is calling -->
        <client-only>
          <poll-component
            v-if="showPoll"
            :poll-data="{}"
          />
        </client-only>
      </div>
    </template>
    <template v-slot:sidebar-ads>
      <sidebar-ads v-if="loading === 0 && post" :sections="contentSections" />
    </template>
    <div ref="footScriptContainer" />
  </component>
</template>

<script>
import AdminToolbar from '@/components/Global/AdminToolbar.vue';
import TextToSpeech from '@/components/Posts/TextToSpeech.vue';
import UserByline from '@/components/User/UserByline.vue';
import SocialShare from '@/components/SocialShare/SocialShare.vue';
import PollComponent from '@/components/EngagementToolkit/PollComponent.vue';
import CommentThread from '@/components/Comments/CommentThread.vue';
import SidebarAds from '@/components/Ads/SidebarAds.vue';
import ReactionGrid from '@/components/Reactions/ReactionGrid.vue';
import EditThisLink from '@/components/Posts/EditThisLink.vue';
import ReindexThisLink from '@/components/Posts/ReindexThisLink.vue';
import PostPasswordForm from '@/components/Posts/PostPasswordForm.vue';
import NutritionInfo from '@/components/NutritionInfo/NutritionInfo.vue';
import HeroComponent from '@/components/Posts/HeroComponent.vue';
import { GET_SINGLE_POST_QUERY } from '@/graphql/queries/post-queries';
import directToPageMixin from '@/mixins/direct-to-page-mixin';
import { postJsonLd } from '@/utils/structured-data';
import { mapGetters } from 'vuex';
import CrossPublishingIndicator from '@/components/Posts/CrossPublishingIndicator.vue';
import { initializeWistiaAds, cleanUpWistiaAd } from '@/utils/wistia-ads';

export default {
  name: 'PostPage',
  components: {
    AdminToolbar,
    TextToSpeech,
    SocialShare,
    UserByline,
    PollComponent,
    CommentThread,
    SidebarAds,
    ReactionGrid,
    EditThisLink,
    ReindexThisLink,
    PostPasswordForm,
    NutritionInfo,
    HeroComponent,
    CrossPublishingIndicator,
  },
  mixins: [directToPageMixin],
  data() {
    return {
      contentSections: [],
      dataLayer: {},
      loading: 0,
      postId: this.$route.params.id || this.$route.query.preview_id || null,
      isPreview: this.$route.query.preview === 'true',
      slug: this.$route.params.slug || null,
      category: this.$route.params.category || null,
      post: undefined,
      referencesIsClosed: true,
      postPasswordEntered: false,
    };
  },
  computed: {
    ...mapGetters(['siteHasFeature', 'isAdmin', 'getSiteSummary', 'is404']),
    lastUpdatedOrAvailableDate() {
      if (!this.post.reviewedby) {
        return this.post.modified || this.post.date;
      }
      return '';
    },
    postIsAPatientInsider() {
      return this.post?.type === 'cas' && this.contentSections?.[0]?.type === 'PatientInsiderHero';
    },
    htmlsForSpeech() {
      if (!process.browser) { return []; }
      return [`${this.post.title}.`].concat(this.contentSections.filter((s) => s.type === 'HTML').map((s) => s.options.content));
    },
    layoutClasses() {
      if (this.post && this.post.type) {
        const classes = [`layout--${this.post.type}`];

        if (this.post.isQuizResult) classes.push('layout--xp-results');

        if (this.postIsAPatientInsider) classes.push('layout--patient-insider');

        return classes.join(' ');
      }
      return '';
    },
    totalComments() {
      if (!this.post.comments) { return 0; }
      return this.post.comments.reduce(
        (accumulator, comment) => {
          if (comment.comments) {
            return accumulator + comment.comments.length;
          }
          return accumulator;
        },
        this.post.comments.length,
      );
    },
    hasAuthContent() {
      return this.contentSections.some((s) => s.isAuthwalled) && !this.$store.state.currentUser.loggedIn;
    },
    showExcerpt() {
      return ['quiz', 'gallery'].includes(this.post.type);
    },
    showFeatured() {
      return this.post.type !== 'cas' && this.featuredImage.url;
    },
    featuredImage() {
      return this.post.featuredMedia[0] || {};
    },
    hideByline() {
      const typesWithoutByline = ['cas', 'quiz', 'gallery'];
      const templatesWithoutByline = ['custom-registration'];
      const hasDisableBylineTag = this.post.tags ? this.post.tags.some((t) => t.name === 'disable-byline') : false;

      return hasDisableBylineTag
        || typesWithoutByline.includes(this.post.type)
        || templatesWithoutByline.includes(this.postTemplate);
    },
    hideSocialShare() {
      const typesWithoutShare = ['cas', 'quiz', 'gallery', 'sponsored_article'];
      const templatesWithoutShare = ['custom-registration'];

      return typesWithoutShare.includes(this.post.type)
        || templatesWithoutShare.includes(this.postTemplate);
    },
    hideEngagement() {
      const typesWithoutComments = ['cas', 'page', 'quiz', 'gallery', 'sponsored_article'];
      const templatesWithoutComments = ['custom-registration'];
      const notOriginalSite = this.post.originalSite && this.post.originalSite !== this.$site.prefix;

      return typesWithoutComments.includes(this.post.type)
        || templatesWithoutComments.includes(this.postTemplate)
        || notOriginalSite;
    },
    showPoll() {
      const typesWithPoll = ['post', 'story', 'recipe'];
      return typesWithPoll.includes(this.post.type);
    },
    postTemplate() {
      if (!this.post || !this.post.metas) return null;
      let templateName = 'default';
      // eslint-disable-next-line no-underscore-dangle
      const templateFileName = this.post.metas._wp_page_template;

      if (templateFileName) {
        templateName = templateFileName.replace('page_', '').replace('.php', '');
      }

      return templateName;
    },
    postLayout() {
      const pageIsCasType = this.post && this.post.type === 'cas';

      if (this.is404) return 'full-bleed-layout';
      if (pageIsCasType || this.postTemplate === 'full-bleed') return 'full-bleed-layout';
      if (this.postTemplate === 'custom-registration') return 'custom-registration-layout';
      return 'sidebar-layout';
    },
    postTitle() {
      if (this.isSponsoredArticle) {
        return `Sponsored: ${this.post.title}`;
      }

      return this.post.title;
    },
    isSponsoredArticle() {
      return this.post.type === 'sponsored_article';
    },
    showDefaultSponsorDisclaimer() {
      return this.isSponsoredArticle && !this.post.hideDefaultSponsorDisclaimer;
    },
    showPost() {
      return this.post && (!this.post.password || this.postPasswordEntered);
    },
    showPasswordForm() {
      return this.post && !!this.post.password && !this.postPasswordEntered;
    },
    isRecipePost() {
      return this.post?.type === 'recipe';
    },
    isStoryPost() {
      return this.post?.type === 'story';
    },
    postShouldNotBeIndexed() {
      return (!this.siteHasFeature('Recipes') && this.isRecipePost)
        || (!this.siteHasFeature('Stories') && this.isStoryPost)
        || this.postTemplate === 'custom-registration';
    },
    showAdminToolbar() {
      return this.isAdmin && this.$site.settings.admin_toolbar && !!this.post;
    },
    headerShouldBeHidden() {
      if (!this.contentSections || !this.contentSections[0]) return false;

      const sectionTypesThatReplacePostHeading = [
        'PatientInsiderHero',
      ];

      return sectionTypesThatReplacePostHeading.includes(this.contentSections[0].type);
    },
    showComments() {
      return !this.hideEngagement
        && this.siteHasFeature('Comments')
        && this.post.commentStatus !== 'closed';
    },
    displayReviewedbyText() {
      return this.post.reviewedby && [
        'post',
        'story',
        'page',
        'recipe',
      ].includes(this.post.type);
    },
    reviewedByTextAndLink() {
      if (!this.post.reviewedby) {
        return '';
      }
      return 'Reviewed by: <a href="https://health-union.com/medical-review-board/" target="_blank" rel="noopener noreferrer nofollow">HU Medical Review Board</a>';
    },
    lastReviewed() {
      if (!this.post.reviewedby) {
        return '';
      }
      return this.post.reviewedby.split('|')[1];
    },
    reviewedbyText() {
      return [
        this.reviewedByTextAndLink,
        this.lastReviewed,
        !this.post.modified && this.post.date && `Last updated: ${this.formatDate(this.post.date)}`,
        this.post.modified && `Last updated: ${this.formatDate(this.post.modified)}`,
      ].filter((text) => !!text).join(' | ');
    },
    showCrossPublishing() {
      return this.post?.crossPublishedSites?.length > 0;
    },
    allSitesForPost() {
      if (!this.post) return [];

      return [
        this.post.originalSite,
        ...this.post.crossPublishedSites,
      ];
    },
    servePrerollAds() {
      return this.post?.tags ? this.post?.tags.some((t) => t.name === 'serve-preroll-ads') : false;
    },
  },
  watch: {
    // This is necessary as the apollo result callback will run twice on SSR,
    // possibly due to cache-and-network policy
    post(to) {
      if (!to) { return; }
      this.onLoad();
    },
    servePrerollAds() {
      // This needs to be in a watcher as opposed to the mounted hook to account for SPA
      if (this.servePrerollAds) {
        initializeWistiaAds(
          this.$gam.network_id,
          this.$gam.targetingData,
          this.$site.settings.autoplay_preroll_ads,
        );
      }
    },
  },
  mounted() {
    this.updatePasswordEntered();
  },
  beforeDestroy() {
    if (this.servePrerollAds) cleanUpWistiaAd();
  },
  methods: {
    parseAndSetContent() {
      const noContentOrAlreadyParsed = !this.post || this.post.content === 'null' || this.contentSections.length > 0;
      if (noContentOrAlreadyParsed) { return; }
      // TODO NEXUS-5320 this is backwards compatible so we can change the datalayar from a json string to a real json object on the BE next
      let dataLayer = {};
      if (typeof this.post.dataLayer === 'object') {
        dataLayer = this.post.dataLayer;
      } else if (typeof this.post.dataLayer === 'string') {
        dataLayer = JSON.parse(this.post.dataLayer);
      }
      this.dataLayer = { ...dataLayer, comment_count: this.post.commentCount };
      this.contentSections = JSON.parse(this.post.content).sections;
    },
    updatePasswordEntered() {
      if (!process.browser || !this.post) { return; }
      const authedPostIds = (window.localStorage.getItem('authedPostIds') || '').split(',');
      if (authedPostIds.includes(this.post.id)) {
        this.postPasswordEntered = true;
      }
    },
    handleGoToComments() {
      window.history.pushState(
        {},
        null,
        `${this.$route.path}#comment-thread`,
      );
      this.openTheComments();
    },
    openTheComments() {
      this.$refs.commentThread.openComments();
    },
    toggleReferences() {
      this.referencesIsClosed = !this.referencesIsClosed;
    },
    openReferences() {
      this.referencesIsClosed = false;
    },
    setJsonLd() {
      this.$store.dispatch(
        'setContentJsonLd',
        postJsonLd(
          this.post,
          this.$route.path,
          this.$root.hostname,
          this.$site.settings.condition_name,
          { hasAuthContent: this.hasAuthContent },
        ),
      );
    },
    formatDate(dateString) {
      const [year, month] = dateString.split('-');
      const monthIndex = month - 1;
      return new Date(year, monthIndex).toLocaleString('en-US', { month: 'long', year: 'numeric' });
    },
    onLoad() {
      this.parseAndSetContent();
      this.setJsonLd();

      this.$store.dispatch('setCurrentPostId', this.post.id);

      this.$store.dispatch('updateGlobalContext', {
        page: {
          type: this.post.type,
          id: this.post.id,
        },
      });
      this.$emit('loaded', this.dataLayer);
    },
    removePreviewQueryParam(queryString) {
      return queryString.split('&').filter((query) => !query.includes('preview')).join('&');
    },
    removePreviewRelatedParamsFromQuery(query) {
      const newQuery = { ...query };

      delete newQuery.preview;
      /* eslint-disable-next-line no-underscore-dangle */
      delete newQuery._thumbnail_id;
      delete newQuery.preview_nonce;

      return newQuery;
    },
  },
  metaInfo() {
    let metaObject = {};
    if (this.postShouldNotBeIndexed) {
      metaObject = {
        meta: [
          {
            name: 'robots',
            vmid: 'robots',
            content: 'noindex',
          },
        ],
        link: [],
      };
    } else if (!this.loading && this.post) {
      metaObject = {
        title: this.$root.$options.filters.decodeHtml(this.post.seo.title),
        titleTemplate: '%s',
        meta: [
          { vmid: 'description', name: 'description', content: this.post.seo.metadesc },
          {
            vmid: 'og:title',
            property: 'og:title',
            content: this.post.seo.opengraphTitle,
          },
          {
            vmid: 'og:description',
            property: 'og:description',
            content: this.post.seo.opengraphDescription,
          },
          { vmid: 'og:image', property: 'og:image', content: this.post.seo.opengraphImage },
          {
            vmid: 'twitter:title',
            name: 'twitter:title',
            content: this.post.seo.twitterTitle,
          },
          {
            vmid: 'twitter:description',
            name: 'twitter:description',
            content: this.post.seo.twitterDescription,
          },
          {
            vmid: 'twitter:image',
            name: 'twitter:image',
            content: this.post.seo.opengraphImage,
          },
        ],
        link: [],
        // Don't escape script tag content. Dangerous in the abstract, but we have to trust admins to safely "inject" direct-to-page JS.
        __dangerouslyDisableSanitizers: ['script'],
        ...this.headTags, // from directToPageMixin
      };

      const robots = [];
      if (this.post.seo.metaRobotsNoindex || !!this.post.password) {
        robots.push('noindex');
        metaObject.link.push({ vmid: 'canonical', content: null });
      }
      if (this.post.seo.metaRobotsNofollow) {
        robots.push('nofollow');
      }
      if (robots.length > 0) {
        metaObject.meta.push({ vmid: 'robots', name: 'robots', content: robots.join(',') });
      } else if (this.post.seo.canonical) {
        metaObject.link.push({ vmid: 'canonical', rel: 'canonical', href: this.post.seo.canonical });
      }
    }

    if (this.postIsAPatientInsider) {
      metaObject.link.push({
        href: 'https://fonts.googleapis.com/css2?family=Fahkwang:ital,wght@0,300;0,600;0,700;1,700&amp;display=swap',
        rel: 'stylesheet',
      });
    }

    if (this.servePrerollAds) {
      metaObject.script = [
        { src: 'https://imasdk.googleapis.com/js/sdkloader/ima3.js' },
      ];
    }

    return metaObject;
  },
  apollo: {
    post: {
      query: GET_SINGLE_POST_QUERY,
      variables() {
        return {
          id: this.postId,
          slug: this.slug,
          category: this.category,
          isPreview: this.isPreview,
          types: this.$route.meta.types || ['cas', 'gallery', 'graphic', 'homepage', 'page', 'post', 'quiz', 'sponsored_article'],
          originalSite: this.$route.query.original_site,
        };
      },
      result({ data }) {
        if (data) {
          if (this.$store.getters.isPreviewError) {
            this.$router.replace({
              query: this.removePreviewRelatedParamsFromQuery(this.$route.query),
            });
            return;
          }
          if (!data.post) { return; }

          this.parseAndSetContent();

          if (!process.browser) {
          // make sure that we set the jsonLD
          // for the page during SSR
            this.setJsonLd();
          }

          if (data.post.type === 'homepage' && this.isPreview) {
            this.$router.push({ name: 'homepagePreview', query: { preview: 'true', preview_id: this.postId } });
            return;
          }

          const { post: { permalink } } = data;
          let permapath = `/${permalink}`;
          const encodedPath = encodeURI(this.$route.path).toLowerCase();
          const isPreviewAndUserIsLoggedIn = this.isPreview && this.$store.getters.userIsLoggedIn;
          const shouldRedirectToPostPermalink = !isPreviewAndUserIsLoggedIn && permalink && encodedPath !== permapath;

          if (shouldRedirectToPostPermalink) {
            let queryString = this.$route.fullPath.split('?')[1];

            if (this.isPreview) {
              queryString = this.removePreviewQueryParam(queryString);
            }

            if (queryString) {
              permapath = `${permapath}?${queryString}`;
              this.$router.replace(permapath);
              return;
            }
            this.$router.replace(`${permapath}${this.$route.hash}`);
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_post';
  @import '@/stylesheets/components/_page-heading';
</style>

<style lang="scss"> // no scoped - to reach post content
  @import '@/stylesheets/components/_wistia-pre-roll-ads';
  // Legacy styles used in WP content
  @import '@/stylesheets/legacy/_accordion';
  @import '@/stylesheets/legacy/_advocate';
  @import '@/stylesheets/legacy/_center';
  @import '@/stylesheets/legacy/_explorer-header';
  @import '@/stylesheets/legacy/_highlight';
  @import '@/stylesheets/legacy/_hr';
  @import '@/stylesheets/legacy/_image-alignment';
  @import '@/stylesheets/legacy/_graphic'; // must be after image_alignment!
  @import '@/stylesheets/legacy/_legacy-typography';
  @import '@/stylesheets/legacy/_nutrition-info';
  @import '@/stylesheets/legacy/_quote';
  @import '@/stylesheets/legacy/_sliding-table';
  @import '@/stylesheets/legacy/_sponsored-highlights';
  @import '@/stylesheets/legacy/_table';
  @import '@/stylesheets/legacy/_text';
  @import '@/stylesheets/legacy/_wistia_margin';
  @import '@/stylesheets/legacy/_wp-caption';
  @import '@/stylesheets/overrides/_one-trust-buttons';

  .post__references ol {
    margin-top: $spacing * 2;
    font-size: $font-size-small;
    line-height: $line-height-smaller;
  }
</style>
